.editing-overlay-panel {
}

.editing-overlay-panel-collapsed {
	display:none;
}

.editing-overlay-panel-title {
	display:block;
	background-color: @white;
    color:#3e4960;
	border-bottom:1px solid #454545;
}

.editing-overlay-panel-title-inner {
	padding:30px;
}

.editing-overlay-panel-content {
	border-bottom:1px solid #454545;
}

.editing-overlay-panel-content-inner {
	padding:30px;
}