@import 'colours';

html {
  overflow-y: hidden;
}

body {
  background-color:@ui-content-color;
  color: @tertiary-color;
}

#app {
	margin-top:60px;
	margin-left:250px;
}

.app-inner {
	position:relative;
}

span.error {
  color: @alert-color;
}

// Loading overlay styling
// =======================

@loading-icon-size: 8px;

.loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  text-align: center;
  opacity:1;
  transition: opacity 0.3s;
  background-color: @ui-content-color;

  &.fade-out {
    opacity: 0;
  }
  &.cover-top-bar {
    position:fixed;
    z-index:50000;
  }

  .loading-inner {
    margin-top: 25%;
  }

  .loading-logo {
    margin-bottom: @loading-icon-size;
    img {
      height: 50px;
    }
  }

  .loading-anim {
    margin: 0 auto 15px auto;
    text-align: center;

    > div {
      width: @loading-icon-size;
      height: @loading-icon-size;
      background-color: @tertiary-color;
      margin: 0 @loading-icon-size/3 0 @loading-icon-size/3;

      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.8s infinite ease-in-out both;
      animation: sk-bouncedelay 1.8s infinite ease-in-out both;
    }
    .circle1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }
    .circle2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }

  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
  }

  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }
}
