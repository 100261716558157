.scaffold-asset-preview {
  display: block;
  margin-bottom: 10px;
  transition: color 0.3s;
  &:hover {
    color: #15a4fa;
  }
  i {
    font-size: 60px;
  }
}

.scaffold-asset-buttons {
  transition: color 0.3s;
  i,
  i:hover {
    color: inherit;
  }
}

.scaffold-asset-external-input-buttons {
  margin-top: 10px;
}
