@user-hover-bg-colour: #f3faff;
@user-hover-borderl-colour: #8ea3af;

.user-management-filter {

  .filter-inner {
    display: flex;
    flex-direction: column;

    .sidebar-tag {
      margin-bottom: 0;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .selectize-control {
      padding: 0;

      .selectize-input {
        background: @sidebar-input-color;
        border-color: @sidebar-input-border-color;
        color: @sidebar-input-text-color;
      }
    }
  }

  input[type="radio"],
  input[type="checkbox"] {

    display: none;

    &:checked + .fa-toggle-off:before {
      content: "\f205";
      color: @button-secondary-color;
    }
  }
  label.sidebar-tag {
    display: flex;
    padding: 14px 25px;

    span {
      margin-right: 10px;
      flex-grow: 1;
    }
    i.fa {
      color: @secondary-color;
      font-size: 20px;
    }
  }
}

.userManagement {
    .container {
      .search {
        padding: 10px;
        display: flex;
        background-color: darken(@white, 3%);

        input[type="text"] {
          width: 85%;
          box-sizing: border-box;
        }

        button {
          flex-grow: 1;
          margin-left: 10px;
        }
      }

      .user-management-filter {
        padding: 0 10px;
        background-color: darken(@white, 3%);

        .filter-inner {
          border-top: solid 1px darken(@white, 15%);
        }
      }

      .form-container-style;
      max-width: inherit;
      .tb-heading {
        .col-centered {
          display: flex;
          justify-content: center;
        }
        > div button {
          display: flex;
          align-items: center;
          text-align: left;
          padding-left: 0;
          padding-right: 0;

          &.active {
            font-weight: @font-weight-bold;
          }

          > h4 {
            flex-grow: 1;
          }

          i.fa {
            margin-right: 10px;
          }

          i.fa {
            visibility: hidden;
          }
          &.active {
            i.fa {
              visibility: visible;
            }
          }

          &.sort-down {
            i.fa::before {
              content: "\f0dd";
            }
          }
          &.sort-up {
            i.fa::before {
              content: "\f0de";
            }
          }
        }

        .sort,
        .filter {
          color: fade(@sidebar-color, 50%);
          &.active {
            color: @sidebar-color;
          }
        }
      }
    }

    .view-buttons {
        text-align: right;
        padding-bottom: 20px;
    }
    .action-buttons{
        margin-top: 30px;
        margin-bottom: 15px;
    }

    .tb-row {
        padding: 10px;

        &.align-right {
            text-align: right;
        }
    }

    .user-item {
        cursor: pointer;
        border: 1px solid white;

        &.me .tb-col-inner {
            font-weight: @font-weight-bold;
        }
        &.inactive {
            .tb-col-inner {
                color: #BBBBBB;
            }
        }
        &.locked {
            color: @alert-color;
        }
        &.selected {
            cursor: default;
            border: 1px solid #d6f2f9;
            border-left: 1px solid @user-hover-borderl-colour;
            background-color: @user-hover-bg-colour;
        }

        &:hover {
            border-left: 1px solid @user-hover-borderl-colour;
            background-color: @user-hover-bg-colour;
        }

        .tb-col-inner {
            li {
                margin-left: 0;
                list-style: none;
            }

            .group {
                margin-bottom: 10px;
            }

            span, select, input {
                display: block;
            }
        }

        a {
            cursor: pointer;
            padding-left: 3px;
        }


        button {
            font-size: 90%;
            padding: 5px 7px;
            margin-bottom: 5px;
            width: 100%;
        }

        input {
            border-radius: 3px;
            border: 1px solid #dedede;
            padding: 3px;
            margin-right: 4px;
        }
        select {
            max-width: 115px;
          &[name="delete-options"] {
            max-width: none;
            width: 100%;
          }
        }

        button.resetPassword {
          &.submitted {
            background-color: #2995a8;
            border-color: #2995a8;
          }
        }
    }
}

.addUser {
    .inner {
        .form-container-style;

        padding: 30px;

        li {
            margin-left: 0;
            list-style: none;
        }

        .title {
            padding-bottom: 30px;
            font-size: 16px;
            font-weight: @font-weight-bold;
        }

        form {
            .form-group {
                border: none;
                .field-error {
                    padding-bottom: 5px;
                }
            }
        }
    }
}
