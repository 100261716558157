@import 'colours';

.editor-menu {
  &-inner {
    height:100%;
  }
}

.editor-menu-item {

  background-color: @white;
  transition: box-shadow 0.3s;
  box-shadow: 0px 0px 1px 1px #EEE;
  &:hover {
    box-shadow: 0px 0px 3px 3px #e0e0e0;
    cursor: pointer;
    .project-settings {
      top:0%;
      opacity:1;
    }
  }

  &.expanded {

    .editor-menu-item-inner {
      color: @white;
      background-color: @primary-color;
      transition:background-color 0s;
      border:1px solid @primary-color;
    }

    &.content-type-menu .editor-menu-item-pin {
      border-left: 10px solid @primary-color;
    }

    .editor-menu-item-settings {
      a {
        color: @white;
        transition:all 0.3s;
        &:hover {
          color: darken(@white, 10%);
        }
      }
    }
  }

  &.selected {

    .editor-menu-item-inner {
      color: @white;
      background-color: @primary-color;
      border:1px solid @primary-color;
    }

    &.content-type-menu .editor-menu-item-pin {
      border-left: 10px solid @primary-color;
    }

    .editor-menu-item-settings {
      a {
        color: @white;
        transition:all 0.3s;
        &:hover {
          color: darken(@white, 10%);
        }
      }
    }
  }
}

.editor-menu-layer-actions {
  .primary-hollow {
    i{
      color: @primary-color;
    }
    &:hover,
    &:active,
    &:focus {
      i {
        color: @white;
      }
    }
  }
}
